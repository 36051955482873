import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Our Products | Stardom House Publishers";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c our-services-cover">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           {/* <img src='./images/11.png' className='logo-image'></img> */}
     <h3 className="category-h3">What We Have Done</h3> 
                                    {/* <p className="category-p category-p-a category-p-b">Indulge in the rich and luscious world of Nshozi Cocoa, offering the finest cocoa products in both beans and powder forms.</p>    */}
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>
        <div className='home-service-box home-service-box-a home-service-box-b'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <img src='./images/8.jpg' alt='image' className='service-image service-image-a '></img>
           {/* <h3 className='home-service-title home-service-title-a'>Ghostwriting </h3> */}
          <p className='product-p'>
          Ugx 100,000
            </p> 
            <Link to='/the-pathway-to-greatness' className='order-link' onClick={scrollFunc}>Order Now</Link>

        </div>

        <div className='home-service-box home-service-box-a home-service-box-b'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <img src='./images/6.jpg' alt='image' className='service-image service-image-a '></img>
          {/* <h3 className='home-service-title home-service-title-a'>Ghostwriting </h3>
          <p>
            Our skilled writers craft captivating plots, dynamic characters, and unforgettable twists that will keep your readers on the edge of their seats. Whether you're a seasoned author or a newcomer to the literary world, our team is here to help you bring your story to life. With a rigorous quality assurance process and a commitment to excellence, we guarantee that your finished work will meet your exacting standards.
            </p> */}

        </div>
       
        <div className='home-service-box home-service-box-a home-service-box-b'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/7.jpg' alt='image' className='service-image service-image-a '></img>
          {/* <h3 className='home-service-title home-service-title-a'>Publishing</h3>
          <p>
            Our carefully considered processes are guided by a will to exceed the expectations of your target audience. Created to attract attention, our articulate communication and engaging content will appeal directly to the intended audience. Our publishing service is geared to communicate messages that impact every level of the community. 
            </p> */}

        </div>
        
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Experience the finest cocoa offerings from Nshozi, whether you prefer the essence of unprocessed cocoa beans or the convenience of luxuriously smooth cocoa powder. Unleash your culinary creativity with Nshozi Cocoa and revel in the unparalleled richness of cocoa in every delightful bite and sip.</p> */}
  </section>              
    </section>
  )
}
export default About