import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import BuildingsCarousel from '../components/BuildingsCarousel';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Gallery | Stardom House Publishers";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c our-services-cover">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           {/* <img src='./images/11.png' className='logo-image'></img> */}
     <h3 className="category-h3">Gallery</h3> 
                                    {/* <p className="category-p category-p-a category-p-b">Indulge in the rich and luscious world of Nshozi Cocoa, offering the finest cocoa products in both beans and powder forms.</p>    */}
    <div className='container container-a'>
      
       <BuildingsCarousel></BuildingsCarousel>
    <p className='gallery-p'><strong>The Pathway To Greatness</strong> book launch</p>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Experience the finest cocoa offerings from Nshozi, whether you prefer the essence of unprocessed cocoa beans or the convenience of luxuriously smooth cocoa powder. Unleash your culinary creativity with Nshozi Cocoa and revel in the unparalleled richness of cocoa in every delightful bite and sip.</p> */}
  </section>              
    </section>
  )
}
export default About