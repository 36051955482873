import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Projects from './pages/Projects';
import Equipment from './pages/Equipment';
import Impact from './pages/Impact';
import Services from './pages/OurServices';
import Clients from './pages/Clients';
import Gallery from './pages/NewGallery';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import Roads from './pages/Roads';
import Support from './pages/Support';
import Maintenance from './pages/Maintenance';
import OrganicCocoa from './pages/OrganicCocoa';
import OrganicCoffee from './pages/OrganicCoffee';
import Join from './pages/Join';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";
import Products from "./pages/OurProducts";
import TPTG from "./pages/BookOrdering1";

function App() {
  return (
    <BrowserRouter>
    {/* <TopSection></TopSection> */}
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about' element={<About></About>}></Route>
            <Route path='our-team' element={<Team></Team>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='pictorial' element={<Gallery></Gallery>}></Route>
             <Route path='equipment' element={<Equipment></Equipment>}></Route>
             
            <Route path='certifications' element={<Certification></Certification>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='gallery' element={<Gallery></Gallery>}></Route>
 <Route path='road-construction' element={<Roads></Roads>}></Route>
 <Route path='projects' element={<Projects></Projects>}></Route>
  <Route path='support' element={<Support></Support>}></Route>
  <Route path='maintenance' element={<Maintenance></Maintenance>}></Route>
<Route path='organic-cocoa' element={<OrganicCocoa></OrganicCocoa>}></Route>
  <Route path='organic-coffee' element={<OrganicCoffee></OrganicCoffee>}></Route> 
   <Route path='join' element={<Join></Join>}></Route>
   <Route path='impact' element={<Impact></Impact>}></Route>   
   <Route path='products' element={<Products></Products>}></Route> 
   <Route path='the-pathway-to-greatness' element={<TPTG></TPTG>}></Route>        
             {/* Footer Services Links */}

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
