import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides">
      <Carousel.Item>
        <img
          className="d-block w-100 first-home-slide"
          // src="./images/4.jpeg"
          // src="./images/1.jpg"
          src="./images/2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3 className="slide-h3"></h3> */}
          <p className="slide-p slide-p1">We do Ghostwriting & Publishing</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          //  src="./images/1.jpg"
          // src="./images/3.jpg"
          src="./images/12.jpeg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2">We organize book launch events </p>
        </Carousel.Caption>
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          //  src="./images/1.jpg"
          // src="./images/3.jpg"
          src="./images/3.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2">We help you write your book </p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/17.jpg"
          src="./images/5.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
We're ready to help you bring your story to life
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

    
 
      
    </Carousel>
  );
}

export default CarouselFade;