import React, {useEffect} from 'react';
import { MdStar } from "react-icons/md";
 
 
const About = () => {
    useEffect(()=>{
document.title = "About Us | Stardom House Publishers";

},[]);
   
  return (
    <section className="section-cover">
        
        <div className="content-container-b">
            <article className="about-row">
                <div className="about-image">
                    {/* <div className="image-circle-one"> */}
                        {/* <MdStar className="image-circle-one"/> */}
                    {/* </div> */}
                    
                    {/* <img src='./images/17b.jpg' className="about-photo"></img> */}
<img src='./images/6.jpg' className="about-photo"></img>
                </div>
                <div className="about-text">
                    <div className="text-circle-one">
                        <MdStar ></MdStar>
                    </div>
                    <div className="text-circle-two">
                        <MdStar></MdStar>
                    </div>
                    <h2 className="heading-h2">Our Vision</h2>
                    <p className="paragraph" >
To create a world where every individual has the opportunity to share their unique story and make their mark on the literary landscape.
                    </p>
                    
                </div>

            </article>
            <article className="about-row about-row-a">
                <div className="about-image">
                    {/* <div className="image-circle-one">
                    </div> */}
                    
                    <img src='./images/7.jpg' className="about-photo"></img>

                </div>
                <div className="about-text">
                    {/* <div className="text-circle-one">
                          <MdStar></MdStar>
                    </div> */}
                    <div className="text-circle-two">
                          <MdStar></MdStar>
                    </div>
                    <h2 className="heading-h2">Our Mission</h2>
                    <p className="paragraph" >
To provide aspiring authors the tools and resources they need to bring their ideas to life and share them with a wider audience.
                    </p>
                    
                </div>

            </article>
            <article className="about-row about-row-b">
                <div className="about-image">
                    {/* <div className="image-circle-one">
                    </div> */}
                    
                    <img src='./images/7.jpg' className="about-photo"></img>

                </div>
                <div className="about-text">
                    {/* <div className="text-circle-one">
                          <MdStar></MdStar>
                    </div> */}
                    <div className="text-circle-two">
                          <MdStar></MdStar>
                    </div>
                    <h2 className="heading-h2">Our Core Values</h2>
                    {/* <p className="paragraph" >
We understand that post-harvest losses can be detrimental to farmers' livelihoods and the environment. Through proper training and support, we help farmers implement effective post-harvest practices, minimizing waste and optimizing the quality of their yield.
                    </p> */}
                    <ul className='new-core-values'>
                       <li>Creativity.</li> 
<li>Professionalism.</li>
 <li>Quality.</li>
 <li>Compassion.</li>
 <li>Punctuality.</li>

                    </ul>
                    
                </div>

            </article>

        </div>


    </section>
  )
}
export default About