import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import VisionCarousel from '../components/GoalsCarousel';
import {FaLeaf} from 'react-icons/fa';
import {RiLeafLine} from 'react-icons/ri';
import {GiFallingLeaf} from 'react-icons/gi';
const Contact = () => {
 let [successMessage, setSuccessMessage] = useState(false);
        let [errorMessage, setErrorMessage] = useState(false);
        let [sendingMessage, setSendingMessage] = useState(false);
        let formInput1 = useRef(null);
        let formInput2 = useRef(null);
        let formInput3 = useRef(null);
        let formInput4 = useRef(null);
        let formInput5 = useRef(null);

let sendFunc =()=>{
  setSendingMessage(true);
    setSuccessMessage(false);
    setErrorMessage(false);
  
}


  useEffect(()=>{
document.title = "The Pathway To Greatness | Stardom House Publishers";

},[]);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vspbbpk', 'template_jom00ge', form.current, 'ZxzY95blItTZvlQY8')
      .then((result) => {
          // console.log(result.text);
          // console.log('Message Sent');
          if(result){
setSuccessMessage(true);
          setSendingMessage(false);

              setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

         }

      }, (error) => {
          // console.log(error.text);
           if (error){
setErrorMessage(true);
          setSendingMessage(false);
           setTimeout(() => {
           setErrorMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

          }
      });
  };
  return (
    <>
     <section className="section-cover section-cover-b section-cover-c section-cover-d">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           <img src='./images/8.jpg' className='logo-image logo-image-a'></img>
     {/* <h3 className="category-h3 category-h3-a">Arabica and Robusta Collection</h3>  */}
                                    <p className="category-p category-p-a category-p-b category-p-d">Get yourself a copy by paying Ugx 100,000 via any of the channels listed here and filling out the form below.</p>   
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
              <img src='./images/10.jpg' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>Airtel Money</h3> */}
          <p>Merchant Code: 4363735</p>

        </div>
        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
              <img src='./images/9.jpg' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>Airtel Money</h3> */}
          <p>0774 649729</p>

        </div>
       
        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
               <img src='./images/1.png' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>ABSA (Hannington Premier Center)</h3> */}
           <p><strong>Bank Branch:</strong> Hannington Premier Center</p>
           <p><strong>Account Name:</strong> Stardom House Publishers</p>
           <p><strong>Account No:</strong> 6008187868</p>
        </div>
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Kindly share with us the transaction ID.</p> */}
  </section>              
    </section>
    <section className='section-cover section-cover-a section-cover-form' >
      {/* <div className="image-circle-one image-circle-one-a image-circle-one-b">
                      <GiFallingLeaf></GiFallingLeaf>
      </div>
      <div className="text-circle-one text-circle-one-a text-circle-one-b">
                      <FaLeaf></FaLeaf>
                    </div>
                    <div className="text-circle-two text-circle-two-a text-circle-two-b">
                      <RiLeafLine></RiLeafLine>
                    </div>
                    <div className="text-circle-three">
                      <FaLeaf></FaLeaf>
                    </div> */}
      {/* <h2 className="home-h2 ">Write To Us</h2> */}
         <div className='content-container contact-section'>
          {/* <p className='home-p home-p1'>Interested in our services, or do you have a business challenge? Get in touch with us and we shall add value. That is our promise!</p> */}

<div className='form-cover'>
  <h2 className='heading-h2 heading-h2-a heading-h2-b'>Order Details</h2>
  {/* <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Contact Number</label>
      <input type="number" name="user_number" />
      <label>Subject</label>
      <input type="text" name="user_subject" />


      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}
    <form ref={form} onSubmit={sendEmail}>
      <label>Name:</label>
      <input type="text" name="user_name" ref={formInput1}/>
      <label>Email:</label>
      <input type="email" name="user_email" ref={formInput2}/>
      <label>Contact Number:</label>
      <input type="text" name="user_number" ref={formInput3}/>
      <label>Number of copies:</label>
      <input type="text" name="user_copies" ref={formInput4} />
      <label>Delivery address:</label>
      <input type="text" name="user_address" ref={formInput5} />

{/* 
      <label>Message:</label>
      <textarea name="message" ref={formInput5}/> */}
       <div  className={`${sendingMessage?'success-box':'hide'}`}>Sending...</div>
      <div  className={`${successMessage?'success-box':'hide'}`}>Thank you! Your message has been sent.</div>
      <div className={`${errorMessage?'error-box':'hide'}`}>Oh, sorry! An error occurred.</div>
      <input type="submit" value="Send Message" onClick={sendFunc} />
    </form>
</div>
           

         </div>

    </section>
    </>
  )
}

export default Contact