import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Our Services | Stardom House Publishers";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c our-services-cover">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           {/* <img src='./images/11.png' className='logo-image'></img> */}
     <h3 className="category-h3">Services We Offer</h3> 
                                    {/* <p className="category-p category-p-a category-p-b">Indulge in the rich and luscious world of Nshozi Cocoa, offering the finest cocoa products in both beans and powder forms.</p>    */}
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <img src='./images/5.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Ghostwriting </h3>
          <p>
            Our skilled writers craft captivating plots, dynamic characters, and unforgettable twists that will keep your readers on the edge of their seats. Whether you're a seasoned author or a newcomer to the literary world, our team is here to help you bring your story to life. With a rigorous quality assurance process and a commitment to excellence, we guarantee that your finished work will meet your exacting standards.
            </p>

        </div>
       
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/1.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Publishing</h3>
          <p>
            Our carefully considered processes are guided by a will to exceed the expectations of your target audience. Created to attract attention, our articulate communication and engaging content will appeal directly to the intended audience. Our publishing service is geared to communicate messages that impact every level of the community. 
            </p>

        </div>
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/29.jpeg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Book launch events</h3>
          <p>
           We arrange perfect book launch events with press invitations, chief guest arrangements, refreshments, promotional materials, etc. 
            </p>

        </div>
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/43.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Transcripting</h3>
          <p>
           We transform your audio or video into readable and searchable text. Our professional transcribers deliver accurate, affordable transcripts so you can focus and collaborate on the work that matters.  
            </p>

        </div>
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/2.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Proofreading and editing</h3>
          <p>
          Our team of revision experts improve your work with academic editing, admissions editing, business editing, and editing services for writers. We correct mistakes in grammar, punctuation, and spelling and improve the vocabulary, readability, and expression of your writing. 
            </p>

        </div>
        
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Experience the finest cocoa offerings from Nshozi, whether you prefer the essence of unprocessed cocoa beans or the convenience of luxuriously smooth cocoa powder. Unleash your culinary creativity with Nshozi Cocoa and revel in the unparalleled richness of cocoa in every delightful bite and sip.</p> */}
  </section>              
    </section>
  )
}
export default About