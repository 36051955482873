import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Organic Coffee | Bloomleaf Organic";

},[]);
  return (
    <section className="section-cover section-cover-b">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           <img src='./images/12.jpg' className='logo-image'></img>
     <h3 className="category-h3 category-h3-a">Arabica and Robusta Collection</h3> 
                                    <p className="category-p category-p-a">Experience the best of both worlds with Nshozi Coffee's Arabica and Robusta Collection.</p>   
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Arabica Green Beans</h3>
          <p>Delight in the smooth and well-balanced flavors of our premium Arabica Green Beans. Sourced from high-altitude plantations, these beans offer delicate floral and fruity notes for a refined coffee experience.</p>

        </div>
       
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Arabica Roasted Beans</h3>
          <p>Indulge in the pinnacle of coffee craftsmanship. Our Arabica Roasted Beans are expertly crafted to showcase the richness of their flavors. Enjoy a nuanced taste with mild acidity and a delightful aroma.</p>

        </div>
        <div className='home-service-box'>
          {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Arabica Ground Coffee</h3>
          <p>Convenience meets quality with our Arabica Ground Coffee. Finely textured for optimal extraction, it delivers a consistently smooth and aromatic brew, embodying the essence of Arabica.</p>
        </div>
<div className='home-service-box'>
          {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Robusta Green Beans</h3>
          <p>
           Embrace the bold and robust character of our Robusta Green Beans. Sourced from select plantations, these beans offer a stronger and more intense coffee experience.
            </p>
        </div>

 <div className='home-service-box'>
           {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean>  </span>
          </div> */}
          <h3 className='home-service-title'>Robusta Roasted Beans</h3>
          <p>Experience the strength and intensity of our Robusta Roasted Beans. Expertly roasted for a full-bodied taste with a thick crema, perfect for espresso enthusiasts.</p>
        </div>
    <div className='home-service-box'>
           {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean>  </span>
          </div> */}
          <h3 className='home-service-title'>Robusta Ground Coffee</h3>
          <p>Get a powerful and satisfying cup with our Robusta Ground Coffee. Finely ground for a bold and invigorating brew, it's ideal for espresso shots and specialty coffee drinks.</p>
        </div>

    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
  </section>              
    </section>
  )
}
export default About