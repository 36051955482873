import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaLeaf} from 'react-icons/fa';
import {RiLeafLine} from 'react-icons/ri';
import {GiFallingLeaf} from 'react-icons/gi';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Stardom House Publishers";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='bg-cover' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.806),rgba(0, 0, 0, 0.806)), url("./images/1.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'}}>
        <div className='content-container content-container-a'>
          {/* <div className='circle1'>
          </div>
          <div className='circle2'>
          </div> */}
          <h2 className="home-h2 home-h2-a">Welcome to Stardom House Publishers</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'>
                 Our writers ensure you get impactful, share-worthy content, written in your personal tone that will build your brand and drive clients to your doorstep.
                
                    Our ghostwriting process and experienced team will not just be content producers but will be part of your team so you can spread your unique message. We have amazing authors at your disposal. 
                  
                     We help you create a lasting book that will align with your goals. With our wide experience in writing, editing and publishing books, we are the book and publishing partners you’ve been looking for!
                  </p>

        </div>

    </section>
    
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 128, 0.706),rgba(0, 0, 0, 0.806)), url("./images/2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Objectives</h2>
            <div className='pledge-line'></div>
         
         <p>
          <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To meet client’s objectives through creativity, invention and service orientation.
</p>
 <p>
  <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To raise the standards of the organization in the country and beyond through providing
high quality products and services in an effective and efficient way.
</p>

        </div>
    </section> */}
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Sustainability</h3>
          <p>We consider our clients as our partners and we work together for greater sustainability across each project. Our systems and checklists allow close monitoring of the process from quality control, clean products equipment and real time delivery of products or services.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Experienced</h3>
          <p>We continue to invest in training our people to ensure that we drive improvements in our product and service delivery. This has resulted into an efficient and effective team of professionals who possess the right skills and service knowledge to respond to each unique situation that may arise. </p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaComments></FaComments></span>
          </div>
          <h3 className='home-service-title'>Communication</h3>
          <p>Our partnership approach creates an open line of communication between our clients and our team. We strongly believe in face to face approach to client service management, however, we utilize technology to ensure efficient communication between our clients and our teams. </p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    {/* <section className="section-cover">
        
        <div className="content-container-b">
            
            <article className="about-row about-row-a about-row-c">
                <div className="about-image">
                    <div className="image-circle-one image-circle-one-a">
                      <GiFallingLeaf></GiFallingLeaf>
                    </div>
                    
                    <img src='./images/4a.jpg' className="about-photo"></img>
                        <img src='./images/18a.jpg' className="about-photo"></img>
                </div>
                <div className="about-text">
                    <div className="text-circle-one text-circle-one-a">
                      <FaLeaf></FaLeaf>
                    </div>
                    <div className="text-circle-two text-circle-two-a">
                      <RiLeafLine></RiLeafLine>
                    </div>
                    <h2 className="heading-h2">Empowering Farmers, Nurturing Nature</h2>
                    <p className="paragraph" >
At <strong>Bloomleaf Organic</strong>, we believe that sustainable agriculture is the key to preserving our planet's resources for future generations. By supporting smallholder farmers and promoting organic farming practices, we contribute to a healthier environment and foster a thriving agricultural community.
                    </p>
                    
                </div>

            </article>
           

        </div>


    </section> */}
     <section className='bg-cover' style={{ backgroundImage: `linear-gradient(#2382c1,#2382c1), url("./images/13.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'}}>
        <div className='content-container content-container-a content-container-c'>
             <div className='vision-cover'>
              <h2 className="heading-h2 heading-h2-c">Our System</h2>
              <p>
                Our system keeps every project running like a well-oiled machine so we can focus on what really matters. We’re here to help you reach your goals. Dreaming of a book deal? Interested in tackling the publishing process on your own? We’ve got packages to fit your needs. And, yes, we’ll be here to support you, no matter which path you choose.
                </p>
             </div>
             <div className='mission-cover'>
                <h2 className="heading-h2 heading-h2-c">Why Choose Us?</h2>
               <p>
                We enhance your idea with our creativity and use our professional ghostwriters to add the best words to it. We take you along with us on the journey and keep you posted on all the progress that we make! Every feedback you provide to us is valued and worked upon. We provide quality, compassion, and punctuality to all our clients.

                </p>
             </div>
          
        </div>

    </section>
  
    </>
  )
}

export default Home