import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "The Pathway To Greatness | Stardom House Publishers";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c section-cover-d">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           <img src='./images/8.jpg' className='logo-image logo-image-a'></img>
     {/* <h3 className="category-h3 category-h3-a">Arabica and Robusta Collection</h3>  */}
                                    <p className="category-p category-p-a category-p-b category-p-d">Get yourself a copy by paying Ugx 100,000 via any of the channels listed here and filling out the form below.</p>   
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
              <img src='./images/10.jpg' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>Airtel Money</h3> */}
          <p>Merchant Code: 43637335</p>

        </div>
        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
              <img src='./images/9.jpg' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>Airtel Money</h3> */}
          <p>0774 649729</p>

        </div>
       
        <div className='home-service-box home-service-box-a home-service-box-c'>
            <div className='home-service-icon-cover'>
               <img src='./images/1.png' className='logo-image-b'></img>
          {/* <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span> */}
          </div>
          {/* <h3 className='home-service-title home-service-title-b'>ABSA (Hannington Premier Center)</h3> */}
           <p><strong>Bank Branch:</strong> Hannington Premier Center</p>
           <p><strong>Account Name:</strong> Stardom House Publishers</p>
           <p><strong>Account No:</strong> 6008187868</p>
        </div>
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Kindly share with us the transaction ID.</p> */}
  </section>              
    </section>
  )
}
export default About